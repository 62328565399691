
/**
 * Return the part of domain without suffix.
 *
 * Example: for domain 'foo.com', the result would be 'foo'.
 */
export default function getDomainWithoutSuffix(domain: string, suffix: string): string {
  // Note: here `domain` and `suffix` cannot have the same length because in
  // this case we set `domain` to `null` instead. It is thus safe to assume
  // that `suffix` is shorter than `domain`.
  return domain.slice(0, -suffix.length - 1);
}
